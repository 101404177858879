<template>
    <b-card v-if="'id' in cwo">
        <form @submit.prevent="addMaterial">
            <h3>Materials/tooling used</h3>
            <table v-if="'materials' in cwo" class="table table-bordered table-cwo">
                <tr>
                    <th class="number">{{ $t("forms.no") }}</th>
                    <th style="width: 300px">{{ $t("forms.batchNumber") }}</th>
                    <th>{{ $t("forms.partNumber") }}</th>
                    <th>{{ $t("forms.description") }}</th>
                    <th>{{ $t("forms.quantity") }}</th>
                    <th>{{ $t("forms.quantityTaken") }}</th>
                    <th>{{ $t("forms.remark") }}</th>
                    <th class="actions">{{ $t("forms.actions") }}</th>
                </tr>
                <tr v-for="(item, index) in cwo.materials" :key="index">
                    <td>
                        {{ index + 1 }}
                    </td>
                    <td>
                        {{ item.batchNumber }}
                    </td>
                    <td>
                        {{ item.partNumber }}
                    </td>
                    <td>
                        {{ item.description }}
                    </td>

                    <td>
                        {{ item.quantity }}
                    </td>
                    <td>
                        {{ item.quantityTaken }}
                    </td>
                    <td>
                        {{ item.remark }}
                    </td>
                    <td>
                        <delete-btn v-if="!item.id" @pressDelete="deleteItem(index)"/>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <v-select
                            v-if="stockItems"
                            id="materialBatchSelect"
                            v-model="materialBatchSelect"
                            :options="stockItems"
                            @search="getStockItems"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.materialBatchSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <b-form-input
                            v-model="materialPartNumber"
                            id="materialPartNumber"
                            disabled
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.materialPartNumber.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <b-form-input
                            v-model="materialDescription"
                            id="materialDescription"
                            disabled
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.materialDescription.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>

                    <td>
                        <b-form-input
                            v-model="materialQuantity"
                            id="materialQuantity"
                            disabled
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.materialQuantity.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <b-form-input
                            v-model="materialQuantityTaken"
                            id="materialQuantityTaken"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.materialQuantityTaken.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <b-form-input :placeholder="cwo.referenceNumber" v-model="materialRemark" id="materialRemark"/>
                    </td>
                    <td>
                        <b-button variant="gray" @click="addMaterial()">
                            <BootstrapIcon icon="plus" size="1x"/>
                            Add
                        </b-button>
                    </td>
                </tr>
            </table>
        </form>
    </b-card>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {maxValue, numeric, required} from "@vuelidate/validators";
import vSelect from "vue-select";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

export default {
    components: {
        vSelect,
        DeleteBtn,
    },
    props: {
        cwoObject: {Object, required: true},
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            materialBatchSelect: {required},
            materialDescription: {required},
            materialPartNumber: {required},
            materialQuantity: {required, numeric},
            materialQuantityTaken: {required, maxValue: maxValue(this.materialQuantity)},
        };
    },
    watch: {
        materialBatchSelect() {
            if (this.materialBatchSelect) {
                this.materialDescription = this.materialBatchSelect.description;
                this.materialPartNumber = this.materialBatchSelect.partNumber;
                this.materialQuantity = this.materialBatchSelect.quantity;
                // this.materialRemark = this.materialBatchSelect.remark;
                if (this.cwoObject && 'referenceNumber' in this.cwoObject) {
                    this.materialRemark = 'SI1: ' + this.cwoObject.referenceNumber + " (" + this.cwoObject.acRegistration + ")";
                }
            }
        },
    },
    data() {
        return {
            cwo: this.cwoObject,
            materialBatchSelect: null,
            materialDescription: null,
            materialPartNumber: null,
            materialQuantity: null,
            materialQuantityTaken: null,
            materialRemark: null,
            stockItems: null,
        };
    },
    created() {
        this.getStockItems('', false);
    },
    methods: {
        async addMaterial() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.cwo.materials.push({
                    description: this.materialDescription,
                    batchNumber: this.materialBatchSelect.batchNumber,
                    partNumber: this.materialPartNumber,
                    quantity: parseFloat(this.materialQuantity).toString(), // The type of the "quantity" attribute for class "App\BusinessLogic\CWO\Entity\CWOMaterial" must be one of "string" ("float" given).

                    quantityTaken: parseFloat(this.materialQuantityTaken).toString(), // The type of the "quantityTaken" attribute for class "App\BusinessLogic\CWO\Entity\CWOMaterial" must be one of "string" ("int" given).

                    stockItemId: parseInt(this.materialBatchSelect.id),
                    remark: this.materialRemark === undefined ? this.cwo.referenceNumber : this.materialRemark,
                });
                this.$emit("objectChange", this.cwo.materials);

            }
        },
        getStockItems(search, loading) {
            const params = {
                station: this.cwo.station.name,
                type: [1,2,3,5]
            };
            if(loading) {
                loading(true)
            }
            if(search && search.length) {
                params.search = search
            }

            params["quantity[gt]"] = 0;
            this.$StockItems.getCollection({params}).then((response) => {
                this.stockItems = [{label: 'Nothing found'}]
                if(loading) {
                    loading(false)
                }
                if(response.data["hydra:member"].length) {
                    this.stockItems = response.data["hydra:member"];
                    this.stockItems.forEach((element) => {
                        element.label = element.partNumber  + " - " + element.batchNumber;
                    });
                }
            });
        },
        deleteItem(index) {
            this.cwo.materials.splice(index, 1);
            this.$emit("objectChange", this.cwo.materials);
        },
    },
};
</script>

<style>
</style>

