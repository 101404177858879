<template>
    <h3>Audit trail</h3>
    <table v-if="statuses" class="table table-bordered table-cwo">
        <tr v-for="(item, index) in statuses" :key="index">
            <td v-if=" item.fromStatus !== 'NEW'">
                <strong>{{ item.employee.givenName }} {{ item.employee.familyName }}</strong> changed status from
                <b-badge :variant="statusClass(item.fromStatus)">{{ item.fromStatus }}</b-badge>
                to
                <b-badge :variant="statusClass(item.toStatus)">{{ item.toStatus }}</b-badge>
                at
                {{ $helper.formatDate(item.createdAt) }} {{ $helper.formatTime(item.createdAt) }}
            </td>
            <td v-if=" item.fromStatus === 'NEW'">
                <strong>{{ item.employee.givenName }} {{ item.employee.familyName }}</strong>
                created new CWO <span v-if="item.fromCwoReference"> from {{ item.fromCwoReference }}</span> with status
                <b-badge :variant="statusClass(item.toStatus)">{{ item.toStatus }}</b-badge>
                at
                {{ $helper.formatDate(item.createdAt) }} {{ $helper.formatTime(item.createdAt) }}
            </td>
        </tr>
    </table>
</template>

<script>

export default {
    name: "AuditTrail",
    props: {
        statuses: {Object, required: true},
    },
    methods: {
        statusClass(status) {
            let className = 'primary'
            if (status === 'CLOSED') {
                className = 'success'
            }
            if (status === 'CONFIRMED') {
                className = 'danger'
            }
            if (status === 'DEFERRED') {
                className = 'warning'
            }
            return className
        },
    }
};
</script>
